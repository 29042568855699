
// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
$mainFont : 'Nunito', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;

/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
  // outline: 1px solid #ff0000;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  background: #fff;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-family: $mainFont;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}


//this is the global container 
.app-container {
  & { height: 100vh; width: 100vw; }
}

.main-container {
  & { height: 100%; width: 100%; }
}

.unsub-container {
  & { display: flex; align-items: center; justify-content: center; height: 100%; width: 100%; }
  .loading {
    & { display: flex; align-items: center; justify-content: center; height: 100%; width: 100%; }
  }
}

.link {
  & { font-weight: 600; font-size: 16px; color: #111; text-decoration: none; border-bottom: 2px solid transparent; transition: border-bottom 250ms ease;}
  &:visited { color: #323232; }
  &:hover { border-bottom: 2px solid #323232; }
}

.btn-primary {
  & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 500; background: #AA7B59; border: 2px solid transparent; color: #fff; box-shadow: 0 0 5px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
  &:hover { filter: drop-shadow(0 0 5px #00000030) brightness(105%) saturate(105%); }
  &:disabled {  background: #afafaf !important; }
} 

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  
}

::-webkit-scrollbar-thumb {
  background-color: #5e5e5e;
  outline: 1px solid #ffffff;
  border-radius: 2px;
}

hr {border: none; height: 1px; color: #efefef; background-color: #efefef; }
